/** @jsx jsx */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect
} from 'react';
import { jsx } from '@emotion/core';
import { DataBlock } from './DataBlock';
import { ChangePasswordModal } from '../modals/ChangePasswordModal';
import { ButtonSize, ButtonColor, Alert, AlertVariant, ButtonGroup, Button as MButton, Spacer } from 'react-magma-dom';
import { ToggleContent } from '../modals/ToggleContent';
import { ProfileUpComm } from '../ProfileData';
import { UserDataKeys } from '../UserDataKeys';
import { PromoteModal } from '../modals/PromoteModal';
import { LoginInput } from '../../../data-inputs/validated/LoginInput';
import { AltLoginInput } from '../../../data-inputs/validated/AltLoginInput';
import { emailLoginLabel, loginLabel } from '../../../basic/LoginLabel';
import { trackingClient } from '../../../../ext/tracking';
import { RequiredFieldNotice } from '../../../basic/RequiredFieldNotice';
import { DEV_HELP_GLOBAL } from '../../../../dev/dev-barrier/safe-ref';
import { MergeLoginsModal, MergeState } from '../modals/MergeLoginsModal';
import { Button } from '../../../magma-wrap/Button';
import { getUserByEmail, isMergeable } from '../../../../api';
import { LinkContactSupport } from '../../../basic/links/LinkContactSupport';
import { Spinner } from '../../../magma-wrap/Spinner';
import { notifyToForceLogout } from '../../../../ext/IframeComm';
import { featureFlags } from '../../../../frontendBackend';
import { APIError } from '../../../../APIError';
import { StudentProfileUserData } from '../../StudentProfile/StudentProfileData';
import { ChangeAlternateLoginModal } from '../modals/ChangeAlternateLoginModal';
import { DeleteAltLoginConfirmationModal } from '../modals/DeleteAltLoginConfirmationModal';
import { UsernameLoginInputBlock } from './UsernameLoginInputBlock';

export const TEST_ID = {
  DATA_BLOCK_ACCOUNT_INFO: 'data-block-account-info',
  BUTTON_CHANGE_PASSWORD: 'change-password-button',
  BUTTON_PROMOTE_LOGIN: 'promote-login',
  BTN_MERGE: 'btn-merge',
  MERGEABLE_ALERT: 'mergeable-alert',
  PROMOTE_LOGIN_TEST_ID: 'promote-login',
  BTN_CHANGE_ALT_LOGIN: 'btn-change-alt-login',
  BTN_DELETE_ALT_LOGIN: 'btn-delete-alt-login'
};

export enum MergeableState {
  init = 'init',
  able = 'able',
  unable = 'unable',
  error = 'error',
  checking = 'checking'
}

const MERGE_MESSAGING = {
  [MergeableState.init]: `Add/edit the alternate ${loginLabel} to merge`,
  [MergeableState.able]: 'Click to merge',
  [MergeableState.unable]: 'Unable to merge with given accounts',
  [MergeableState.error]: 'Error when checking mergeability',
  [MergeableState.checking]: 'Checking mergeability'
};

const SWAP_MESSAGING = {
  noAlternate: 'Please enter an alternate email',
  swapable: `Use alternate ${emailLoginLabel} as primary ${emailLoginLabel}`
};

export interface AccountInfoBlockTestProps {
  showMerge?: boolean;
  showEmailSwap?: boolean;
}

interface UseCheckCanPromote {
  setCanPromote: (v: boolean) => void;
  _handle?: (e: Error | null) => void;
}

export const useCheckCanPromote = (params: UseCheckCanPromote) => {
  const { setCanPromote, _handle } = params;
  return useCallback(
    (userData: any, _devOverride?: any) => {
      const { getUserByEmail: _apiGetUserByEmail = getUserByEmail } =
      _devOverride ?? {};

      const username = userData[UserDataKeys.alternateLogin];
      if (username) {
        _apiGetUserByEmail(username)
          .then((result: any) => {
            _handle?.(null);
            if (undefined === result.found) {
              throw new Error('Expected "found" to be defined');
            }

            setCanPromote(!result.found);
            return result;
          })
          .catch((e: Error) => {
            _handle?.(e);
            setCanPromote(false);
          });
      }
    },
    [setCanPromote, _handle]
  );
};

const array = new Uint32Array(10);
const rand = window.crypto.getRandomValues(array);
const idGen = () => {
  return [
    Date.now().toString(36),
    rand
  ].join('-');
};

export interface AccountInfoBlockProps extends ProfileUpComm {
  userData: StudentProfileUserData;

  // This allows for iframe messaging to be accomplished
  callbackOrigin?: string;

  showMerge?: boolean;
  showEmailSwap?: boolean;
  onAlternateLoginChange?: (e: any) => void;
  onAlternateLoginValidated?: any;
  persistAltLoginChangeEvent?: boolean;
}

export const AccountInfoBlock: React.FunctionComponent<AccountInfoBlockProps> = props => {
  const {
    userData,
    upComm,
    callbackOrigin,
    showEmailSwap: showEmailSwapInit = true,
    showMerge: showMergeInit
  } = props;

  const [_apiIsMergeable, _setApiIsMergeable] = useState(() => isMergeable);
  const curMergeCall = useRef(idGen());

  const category = trackingClient.event.profileEdit;


  const [showPromoteModal, setShowPromoteModal] = useState(false);

  const activeIdp = window.backend.idpInfo?.activeIdp;
  const [showMerge, setShowMerge] = useState(
    activeIdp
      ? false
      : showMergeInit ?? featureFlags.isMergeVisible()
  );
  const showEmailSwap = activeIdp
    ? false
    : showEmailSwapInit;
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [mergeableState, setMergeableState] = useState(MergeableState.init);
  const [secondaryLogin, setSecondaryLogin] = useState(
    userData[UserDataKeys.alternateLogin]
  );
  const [showChangeAltLoginModal, setShowChangeAltLoginModal] = useState(false);
  const [showDeleteAltLoginConfirmationModal, setShowDeleteAltLoginConfirmationModal] = useState(false);
  
  const [canPromote, setCanPromote] = useState(
    !!secondaryLogin
  );

  const [promoteLabel, setPromoteLabel] = useState(
    SWAP_MESSAGING.noAlternate
  );
  const isIgnoreError = (err: APIError) => {
    return 400 === err?.getStatusCode?.() ||
      404 === err?.getStatusCode?.() ||
      409 === err?.getStatusCode?.();
  };

  const canMerge = showMerge && userData.loginId && secondaryLogin;


  const returnWhenbadCallId = (curMergeCall: React.MutableRefObject<string>, callId: string) => {
    if (curMergeCall.current !== callId) {
      // Cancel the call's result handling
      return;
    }
  };
  const checkMergeable = useCallback(() => {
    if (canMerge) {
      const callId = idGen();
      curMergeCall.current = callId;
      setMergeableState(MergeableState.checking);
      _apiIsMergeable({
        primary: userData.loginId,
        secondary: secondaryLogin
      })
        .then(v => {
          returnWhenbadCallId(curMergeCall, callId);
          if (v) {
            setMergeableState(MergeableState.able);
          } else {
            setMergeableState(MergeableState.unable);
          }
        })
        .catch((err) => {
          returnWhenbadCallId(curMergeCall, callId);
          if (
            isIgnoreError(err)
          ) {
            setMergeableState(MergeableState.unable);
          } else {
            setMergeableState(MergeableState.error);
          }
        });
    }
  }, [showMerge, userData.loginId, secondaryLogin, _apiIsMergeable]);

  const checkCanPromote = useCheckCanPromote({ setCanPromote });

  useLayoutEffect(() => {
    if (MergeableState.able === mergeableState) {
      setCanPromote(false);
    }
  }, [mergeableState]);

  useEffect(() => {
    setCanPromote(false);
    if (
      !!secondaryLogin &&
      showEmailSwap
    ) {
      const newUserData = Object.assign({}, userData);
      newUserData[UserDataKeys.alternateLogin] = secondaryLogin;
      checkCanPromote(newUserData);
    }
  }, [showEmailSwap, userData, checkCanPromote, secondaryLogin]);

  useEffect(() => {
    if (!secondaryLogin) {
      setPromoteLabel(SWAP_MESSAGING.noAlternate);
    } else {
      setPromoteLabel(SWAP_MESSAGING.swapable);
    }
  }, [secondaryLogin]);
  
  useEffect(() => {
    setMergeableState(MergeableState.unable);
    if (!upComm?.ctx.isEditing) {
      // Only check when not currently editing
      checkMergeable();
    }
  }, [checkMergeable, secondaryLogin, upComm?.ctx.isEditing]);

  // DevHelp commands aren't tested
  /* istanbul ignore next */
  {
    window?.[DEV_HELP_GLOBAL]?.useCommand?.({
      id: 'profile.toggleMerge',
      display: 'Toggle merge button',
      action: () => {
        setShowMerge(o => !o);
      }
    });

    window?.[DEV_HELP_GLOBAL]?.useCommand?.({
      id: 'profile.toggleMergeEnabled',
      display: 'Toggle merge enabled',
      action: () => {
        setShowMerge(true);
        setMergeableState(o => {
          if (MergeableState.able !== o) {
            return MergeableState.able;
          } else {
            return MergeableState.unable;
          }
        });
      }
    });

    window?.[DEV_HELP_GLOBAL]?.useCommand?.({
      id: 'profile.mergeable.success',
      display: 'Mergeable success',
      action: () => {
        setShowMerge(true);
        _setApiIsMergeable(() => async () => true);
      }
    });

    window?.[DEV_HELP_GLOBAL]?.useCommand?.({
      id: 'profile.mergeable.delaySuccess',
      display: 'Mergeable success delay',
      action: () => {
        setShowMerge(true);
        _setApiIsMergeable(() => () => {
          return new Promise(res => {
            setTimeout(() => {
              res(true);
            }, 3000);
          });
        });
      }
    });

    window?.[DEV_HELP_GLOBAL]?.useCommand?.({
      id: 'profile.mergeable.denied',
      display: 'Mergeable denied',
      action: () => {
        setShowMerge(true);
        _setApiIsMergeable(() => async () => false);
      }
    });

    window?.[DEV_HELP_GLOBAL]?.useCommand?.({
      id: 'profile.mergeable.failure',
      display: 'Mergeable failure',
      action: () => {
        setShowMerge(true);
        _setApiIsMergeable(() => async () => {
          throw new Error('DevHelp error');
        });
      }
    });
  }

  return (
    <React.Fragment>
      <DataBlock
        blockName="My Account Info"
        blockHeader={
          <React.Fragment>
            <span>
              Account Name: <b>{userData[UserDataKeys.accountName]}</b>
            </span>
            <Spacer size={12} />
            <RequiredFieldNotice/>
          </React.Fragment>
        }
        testId={TEST_ID.DATA_BLOCK_ACCOUNT_INFO}
      >
        <UsernameLoginInputBlock
          userData={userData}
          upComm={upComm}
        />
        <ButtonGroup style={{ display: 'inline-flex', margin: '0 5 15 0' }}>
          {showEmailSwap && (
            <MButton
              color={ButtonColor.secondary}
              disabled={!canPromote}
              onClick={() => {
                category.makeAlternateEmailUsernameClick();
                setShowPromoteModal(true);
              }}
              size={ButtonSize.small}
              title={promoteLabel}
              onMouseOver={() =>
                category.mouseOverChangeUsername({
                  tags: { label: promoteLabel.toLowerCase() }
                })
              }
              onFocus={() => {
                // No-op, required by tslint but is outside scope of tracking
              }}
              testId={TEST_ID.PROMOTE_LOGIN_TEST_ID}
            >{promoteLabel}</MButton>
          )}
          {showMerge && (

            <MButton
              size={ButtonSize.small}
              disabled={MergeableState.able !== mergeableState}
              onClick={() => setShowMergeModal(true)}
              color={ButtonColor.secondary}
              testId={TEST_ID.BTN_MERGE}
              title={MERGE_MESSAGING[mergeableState]}
            >Merge accounts</MButton>
          )}
        </ButtonGroup>
        {showMerge && (
          <React.Fragment>
            {MergeableState.checking === mergeableState && (
              <Spinner aria-label="Checking Mergeability"/>
            )}
            {MergeableState.error === mergeableState && (
              <Alert
                variant={AlertVariant.warning}
                onDismiss={setMergeableState.bind(null, MergeableState.init)}
                testId={TEST_ID.MERGEABLE_ALERT}
              >
                There was an issue trying to check if the accounts can be
                merged. Please <LinkContactSupport/>.
              </Alert>
            )}
          </React.Fragment>
        )}
        <div key={secondaryLogin}>
          <AltLoginInput
            readonly={true}
            defaultValue={secondaryLogin}
          />
        </div>
        <ButtonGroup>
          <MButton
            size={ButtonSize.small}
            onClick={() => setShowChangeAltLoginModal(true)}
            color={ButtonColor.secondary}
            testId={TEST_ID.BTN_CHANGE_ALT_LOGIN}
          >Change Alternate {emailLoginLabel}</MButton>

          <MButton
            size={ButtonSize.small}
            onClick={() => setShowDeleteAltLoginConfirmationModal(true)}
            color={ButtonColor.secondary}
            testId={TEST_ID.BTN_DELETE_ALT_LOGIN}
            disabled={!secondaryLogin}
          >Delete Alternate {emailLoginLabel}</MButton>
        </ButtonGroup>

        <ToggleContent
          toggler={toShow => (
            <Button
              onClick={() => {
                category.changePasswordClick();

                toShow();
              }}
              id="change-password-button"
              testId={TEST_ID.BUTTON_CHANGE_PASSWORD}
              value="Change password"
            />
          )}
          content={onHide => (
            <React.Fragment>
              <ChangePasswordModal
                primaryLogin={userData[UserDataKeys.login]}
                onClose={onHide}/>
            </React.Fragment>
          )}
        />

        {showPromoteModal && (
          <PromoteModal
            open={showPromoteModal}
            onClose={() => setShowPromoteModal(false)}
            primaryLogin={userData.loginId}
            upComm={upComm}
            secondaryLogin={secondaryLogin}
          />
        )}

        {showMergeModal && (
          <MergeLoginsModal
            open={showMergeModal}
            onClose={state => {
              setShowMergeModal(false);

              // This should be covered by e2e tests
              /* istanbul ignore next */
              if (MergeState.success === state) {
                if (callbackOrigin) {
                  notifyToForceLogout(callbackOrigin);
                } else {
                  const loc = document.location;
                  const targetUrl = encodeURIComponent(
                    `${loc.protocol}//${loc.host}/profile`
                  );
                  (document.location as any) = `/oauth2/logout?targeturl=${targetUrl}`;
                }
              }
            }}
            primaryLogin={userData.loginId}
            secondLogin={secondaryLogin}
          />
        )}

        {showChangeAltLoginModal && (
          <ChangeAlternateLoginModal
            open={showChangeAltLoginModal}
            primaryLogin={userData.loginId}
            secondaryLogin={secondaryLogin}
            onAlternateChanged={setSecondaryLogin}
            onClose={() => {
              setShowChangeAltLoginModal(false);
            }
            }
          />
        )}
        {showDeleteAltLoginConfirmationModal && (
          <DeleteAltLoginConfirmationModal
            open={showChangeAltLoginModal}
            onAlternateChanged={setSecondaryLogin}
            onClose={() => {
              setShowDeleteAltLoginConfirmationModal(false);
            }
            }
          />
        )}
      </DataBlock>
    </React.Fragment>
  );
};
