import 'cross-fetch/polyfill';
import { APIError } from './APIError';
import { Option } from './components/magma-wrap/shared';
import { UserDataKeys } from './components/screens/Profile/UserDataKeys';

export interface SearchResult {
  found: boolean;
  activating: boolean;
}

export enum UserType {
  student = 'student',
  instructor = 'instructor',
  other = 'other'
  // added 'other' to test issue with jenkins builds
}

export enum HasInCommon {
  true = 'true',
  false = 'false'
}

export const handleErrors = async (
  result: Response,
  body: any
): Promise<void> => {
  const errorCodeFmt = body.userMessage || body.errorCode;
  if (400 <= result.status || errorCodeFmt) {
    if (errorCodeFmt) {
      if (body.userMessage) {
        throw new APIError(body.userMessage, body.errorCode, result.status);
      } else {
        throw new APIError(
          `Server error (${body.errorCode})`,
          body.errorCode,
          result.status
        );
      }
    } else {
      throw new Error('Server error');
    }
  }
};

const getBody = async (result: Response): Promise<any> => {
  const body = await result.text();
  if ('' === body) {
    return {};
  }
  return JSON.parse(body);
};

const fetchAndHandleErrors = async (path: string): Promise<any> => {
  const reqInit: RequestInit = {};
  const result = await fetch(path, reqInit);
  const body = await getBody(result);

  await handleErrors(result, body);

  return { result, body };
};

const patchAndHandleErrors = async (
  path: string,
  payload: any
): Promise<any> => {
  const csrfToken = window.backend.csrfToken;
  const result = await fetch(path, {
    method: 'PATCH',
    body: payload,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': csrfToken
    }
  });
  const body = await getBody(result);

  if (Array.isArray(body.errors) && body.errors.length) {
    throw new Error(body.errors.join('\n'));
  }
  await handleErrors(result, body);

  return { result, body };
};

const putAndHandleErrors = async (
  path: string,
  payload: any
): Promise<any> => {
  const csrfToken = window.backend.csrfToken;
  const result = await fetch(path, {
    method: 'PUT',
    body: payload,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': csrfToken
    }
  });
  const body = await getBody(result);

  if (Array.isArray(body.errors) && body.errors.length) {
    throw new Error(body.errors.join('\n'));
  }
  await handleErrors(result, body);

  return { result, body };
};

export const postAndHandleErrors = async (
  path: string,
  params: any = '{}'
): Promise<any> => {
  const csrfToken = window.backend.csrfToken;
  const result = await fetch(path, {
    method: 'POST',
    body: params,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': csrfToken
    }
  });
  const body = await getBody(result);

  await handleErrors(result, body);

  return { result, body };
};

export const deleteAndHandleErrors = async (path: string): Promise<any> => {
  const csrfToken = window.backend.csrfToken;
  const result = await fetch(path, {
    method: 'DELETE',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': csrfToken
    }
  });
  const body = await getBody(result);

  await handleErrors(result, body);

  return { result, body };
};

export const getUserByEmail = async (email: string): Promise<SearchResult> => {
  return (await fetchAndHandleErrors(`/users/${email}`)).body;
};

export const getPasswordPolicy = async (): Promise<any> => {
  return (await fetchAndHandleErrors('/passwordPolicy')).body;
};

export const studentRegister = async (userData: any): Promise<any> => {
  let path = '/users?userType=student';
  if (userData[UserDataKeys.contextId]){
    path = `${path}&contextId=${userData[UserDataKeys.contextId]}`;
  }
  return (
    await postAndHandleErrors(path,
      JSON.stringify({ profile: userData })
    )
  ).body;
};

export const facultyRegister = async (userData: any): Promise<any> => {
  const {
    entityName,
    entityCity,
    entityState,
    entityCountry,
    entityRegion,
    supervisorName,
    supervisorEmail,
    supervisorPhone,
    ...profile
  } = userData;
  const institutionVerification = {
    entityName,
    entityCity,
    entityState,
    entityCountry,
    entityRegion,
    supervisorName,
    supervisorEmail,
    supervisorPhone
  };
  return (
    await postAndHandleErrors(
      '/users?userType=instructor',
      JSON.stringify({ profile, institutionVerification })
    )
  ).body;
};

export const reactivateUser = async (emailAddress: string): Promise<any> => {
  return (
    await postAndHandleErrors(
      '/reactivate',
      JSON.stringify({ email: emailAddress })
    )
  ).body;
};

export const updateProfile = async (profileData: any): Promise<any> => {
  return (await patchAndHandleErrors(`/users/me`, JSON.stringify(profileData)))
    .body;
};

export const getTimezones = async () => {
  const apiResult = (await fetchAndHandleErrors('/data/timezones')).body;
  const result = [];

  // Setup as an array
  for (const value of Object.keys(apiResult)) {
    const label = apiResult[value];
    result.push({
      item: value,
      label
    });
  }

  // Sort from minus offset to plus offset
  result.sort(({ label: a }, { label: b }) => {
    return a.localeCompare(b);
  });

  return result;
};

export const getCountries = async () => {
  const apiResult = (await fetchAndHandleErrors('/data/countries')).body;
  const countryList: Option[] = [];

  apiResult.forEach((country: any) => {
    const option: Option = { label: country.name, item: country.isoCode, region: country.region };
    countryList.push(option);
  });

  return countryList;
};

export const changeMyPassword = async (
  oldPassword: string,
  replacement: string
) => {
  try {
    return (
      await patchAndHandleErrors(
        '/users/me/password',
        JSON.stringify({
          oldValue: oldPassword,
          replacement
        })
      )
    ).body;
  } catch (error) {
    throw new Error('There was an issue changing your password');
  }
};

enum FactorStatus {
  pending = 'PENDING_ACTIVATION',
  active = 'ACTIVE',
  notSetup = 'NOT_SETUP'
}

interface FactorResponse {
  email?: string;
  status: FactorStatus;
}

const handleFactorResponse = ({ email, status }: FactorResponse) => {
  return {
    email,
    verifying: FactorStatus.pending === status,
    verified: FactorStatus.active === status
  };
};

export const getVerificationStatus = async () => {
  try {
    const response = (await fetchAndHandleErrors('/users/me/factors/email'))
      .body;
    // It'd be good to check the email, something to handle later...
    // The only situation where the email might be wrong is if
    // another tab already swapped the account and the current
    // tab tries to start the process... edge-case.
    return handleFactorResponse(response);
  } catch (error: any) {
    if (error.getErrorCode && 'not-found.factor' === error.getErrorCode()) {
      return handleFactorResponse({ status: FactorStatus.notSetup });
    }
    throw error;
  }
};

export const enrollFactor = async (email: string) => {
  const response = (
    await postAndHandleErrors(
      '/users/me/factors/email',
      JSON.stringify({
        value: email
      })
    )
  ).body;
  return handleFactorResponse(response);
};

export const checkAlternateLoginId = async (userId: string, schoolAffiliation: string, forPasswordReset?: boolean) => {
  let path = '/users/findAlternateLoginId';
  if (forPasswordReset) {
    path += `?forPasswordReset=${forPasswordReset}`;
  }
  return (
    await postAndHandleErrors(
      path,
      JSON.stringify({
        loginId: userId,
        schoolAffiliation: schoolAffiliation
      })
    )
  ).body;
};

export const verifyFactor = async (code: string) => {
  const response = (
    await patchAndHandleErrors(
      '/users/me/factors/email/verification',
      JSON.stringify({
        value: code
      })
    )
  ).body;
  return handleFactorResponse(response);
};

export const swapLogin = async (newLogin: string) => {
  return (
    await patchAndHandleErrors(
      '/users/me/login',
      JSON.stringify({
        value: newLogin
      })
    )
  ).body;
};

export const queryRipsawInstitutionsForSelect = async (
  query: string,
  userType: UserType = UserType.student,
  hasInCommon?: HasInCommon
) => {
  const base = '/data/institutions';
  const queryString = encodeURIComponent(query);
  const url = [
    base,
    '?',
    [`userType=${userType}`, `q=${queryString}`].join('&')
  ];

  if (hasInCommon) {
    url.push(`&hasIdp=${hasInCommon}`);
  }
  
  const urlString = url.join('');

  return filterResultsForRipsaw((await fetchAndHandleErrors(urlString)).body, hasInCommon);
};

export const sendPasswordResetEmail = async (email: string) => {
  return (
    await postAndHandleErrors(
      window.backend.oktaBaseUrl + '/api/v1/authn/recovery/password',
      JSON.stringify({
        username: email,
        factorType: 'EMAIL'
      })
    )
  ).body;
};

interface AuthData {
  username: string;
  password: string;
}

export const authenticate = async (authData: AuthData) => {
  const { username, password } = authData;
  const result = (
    await postAndHandleErrors(
      window.backend.oktaBaseUrl + '/api/v1/authn',
      JSON.stringify({
        username,
        password
      })
    )
  ).body;
  return 'SUCCESS' === result.status;
};

export interface MergeDataPass {
  sourceLogin: string;
  sourcePassword: string;
  targetLogin: string;
  targetPassword: string;
}

export const mergeLogins = async (mergeData: MergeDataPass) => {
  return (await postAndHandleErrors('/users/merge', JSON.stringify(mergeData)))
    .body;
};

interface MergeData {
  primary: string;
  secondary: string;
}

export const isMergeable = async (mergeData: MergeData) => {
  return (
    await postAndHandleErrors('/users/mergeable', JSON.stringify(mergeData))
  ).body;
};

function filterResultsForRipsaw(response: any, hasInCommon?: HasInCommon) {
  const result: Option[] = [];
  for (const institution of response) {
    const cityStateDisplay = formatInstitutionCityStateData(institution);
    let value;
    if (hasInCommon === HasInCommon.true) {
      value = JSON.stringify({
        sawsEntityId: institution.entityId,
        inCommonEntityId: institution.idpId
      });
    } else {
      value = institution.entityId;
    }
    result.push({
      label: institution.name + cityStateDisplay,
      item: value
    });
  }
  return result;
}

export const formatInstitutionCityStateData = (institution: any) => {
  const cityState = [
    institution?.city,
    institution?.stateCode
  ]
    .filter(v => v)
    .join(', ');
  return cityState ? ` (${cityState})` : '';
};

export const unlinkAccount = async () => {
  return deleteAndHandleErrors('/users/me/idpLinks/active');
};

export const revokeAppTokens = async (appUrl: string): Promise<any> => {
  return fetch(appUrl, {
    method: 'DELETE',
    credentials: 'include'
  });
};

export const checkUserSession = async (orgUrl: string): Promise<any> => {
  return fetch(orgUrl + '/api/v1/sessions/me', {
    method: 'GET',
    credentials: 'include'
  });
};

export const changeAlternateLogin = async (
  loginId: string,
  alternateLoginId: string,
  secretValue: string
) => {
  try {
    return (
      await putAndHandleErrors(
        '/users/me/alternateEmail',
        JSON.stringify({
          loginId,
          alternateLoginId,
          secretValue
        })
      )
    ).body;
  } catch (error) {
    throw new Error('There was an issue changing your Alternate Login');
  }
};

export const deleteAlternateLogin = async () => {
  try {
    return (
      await deleteAndHandleErrors('/users/me/alternateEmail')
    );
  } catch (error) {
    throw new Error('There was an issue deleting your Alternate Login');
  }
};

export const getInstitutionById = async (institutionId:string|undefined): Promise<any> => {
  const url = `/data/institutions/${institutionId}/isAutoVerify`;
  try {
    return await fetchAndHandleErrors(url);
  } catch (error:any) {
    throw new Error(error);
  }
};
