// This file is responsible for checking to see if a username given by the user matches
// a district login nameId in the idpLinks of an okta profile.  If a single match is found,
// the alternateLoginId variable in index.ts is set to the corresponding primary okta loginId
// for the account that was found with the matching idp district login nameId.  The sign in widget
// uses this alternateLoginId to transform the username in the request.  This file also does
// necessary dom manipulation to enable the checking of this alternate login id.
import React from 'react';
import ReactDOM from 'react-dom';
import { InstitutionPicker } from '../../components/screens/Profile/institution/InstitutionPicker';
import { checkAlternateLoginId } from '../../api';
import { hideErrorElement, showErrorElement } from './dom-mod';
import { createOktaFormError, removeOktaFormErrorContents } from './dom-build';
import { alternateLoginId, setAlternateLoginId } from './index';

export let institutionPickerError = false;
export let institution = '';
export let institutionPickerRendered = false;

// The following method initiates searching for a single user that has a district login nameId that matches the userId
// that the user input into Alfred.  This is called during the login flow directly after an authentication failure.
export const checkForAlternateLoginAfterFailure = async () => {
  const submitButton = document.getElementById('okta-signin-submit');
  const precedingElement = document.querySelector('.o-form-fieldset-container');
  const errorDiv = document.querySelector('.okta-form-infobox-error');
  const userId = (document.getElementById('okta-signin-username') as HTMLInputElement).value;
  if (submitButton && precedingElement && errorDiv) {
    hideErrorElement(errorDiv);
    setAlternateLoginId(await checkForAlternateLogin(userId, false, submitButton, precedingElement, errorDiv));
    if (alternateLoginId) {
      while (document.getElementsByClassName('beacon-loading').length > 0) {
        await new Promise(f => setTimeout(f, 10));
      }
      document.getElementById('okta-signin-submit')?.click();
    }
  } else {
    setAlternateLoginId('');
  }
};

// The following method adds a button listener to the submit button on the forgot password page so that we can proactively
// check for a single user that has a district login nameId that matches the userId that the user input into Alfred.
// This listener is configured to only execute once, which is why this method internally calls itself in situations
// where we need to listen again for the button click on the page (in cases where an error was rendered and the user didn't 
// advance to the next screen)
export const addListenerToCheckAlternateLoginId = () => {
  const submitButton = document.querySelector('.email-button');
  const precedingElement = document.querySelector('.o-form-fieldset');
  const errorDiv = document.querySelector('.o-form-error-container');

  if (submitButton !== null && precedingElement !== null && errorDiv !== null) {
    submitButton?.addEventListener('click', async function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      const userId = (document.getElementById('account-recovery-username') as HTMLInputElement).value;
      const usernameInput = (document.getElementById('account-recovery-username') as HTMLInputElement);
      // This if block checks for errors related to the institution picker that has already been rendered on the page.
      // We need to prevent the default button click action if there is a validation or connection issue associated with the institution picker.
      if (institutionPickerRendered) {
        if (institutionPickerError) {
          e.preventDefault();
          e.stopPropagation();
          addListenerToCheckAlternateLoginId();
          return;
        } else if (!institution) {
          if (usernameInput?.value.length > 0) {
            // If the usernameInput is populated but the institution is not, we need to add our own error to the page.
            e.preventDefault();
            e.stopPropagation();
            addOktaError(errorDiv, 'We found some errors. Please review the form and make corrections.');
          } else {
            // If the usernameInput is not populated, we allow the Okta sign in widget to add its default error (and remove any we might have added)
            removeOktaFormErrorContents(errorDiv);
            // Subsequent button click is needed to initiate the okta default error on the page.
            (submitButton as HTMLElement).click();
          }
          const wrapper = document.getElementById('hacky-inserter-institution-picker') || createInstitutionPickerWrapperDiv(precedingElement);
          renderInstitutionPicker(wrapper, errorDiv, 'Please select an institution');
          addListenerToCheckAlternateLoginId();
          return;
        }
      }
      if (userId.length > 0) {
        try {
          setAlternateLoginId(await checkForAlternateLogin(userId, true, (submitButton as HTMLElement), precedingElement, errorDiv));
          // If setAlternateLoginId call is successful, click the submit button again to initiate normal send password reset email flow
          (document.querySelector('.email-button') as HTMLElement)?.click();
          // Remove any okta errors that we may have added onto the page
          removeOktaFormErrorContents(errorDiv);
          // Reset the tracking variables back to defaults
          setAlternateLoginId('');
          institutionPickerError = false;
          institution = '';
          institutionPickerRendered = false;
        } catch (error: any) {
          addListenerToCheckAlternateLoginId();
        }
      } else {
        // If userId is not populated on page, remove an errors we might have added and initiate button click so that
        // sign in widget will render its own error on the page.  Re-add the listener to listen for the next button click.
        removeOktaFormErrorContents(errorDiv);
        (submitButton as HTMLElement).click();
        addListenerToCheckAlternateLoginId();
      }
    }, { capture: true, once: true });
  }
};

export const checkForAlternateLogin = async (userId: string, forPasswordReset: boolean, submitButton: HTMLElement, precedingElement: Element, errorDiv: Element) => {
  try {
    if (userId) {
      const result = await checkAlternateLoginId(userId, institution, forPasswordReset);
      if (result && result.loginId) {
        return result.loginId;
      }
    }
  } catch (error: any) {
    if (forPasswordReset) {
      handleAlternateLoginIdErrorsForPasswordReset(error, submitButton, precedingElement, errorDiv);
    } else {
      handleAlternateLoginIdErrors(error, submitButton, precedingElement, errorDiv);
    }
  }
  return '';
};

const handleAlternateLoginIdErrors = (error: any, submitButton: HTMLElement, precedingElement: Element, errorDiv: Element) => {
  if (error.getErrorCode() === 'gen.unexpected.multipleAccountMatch') {
    showErrorMessageElementToContactSupport(errorDiv);
  } else if (error.getErrorCode() === 'gen.institution-required') {
    hackyInstitutionPickerInserter(submitButton, precedingElement, errorDiv);
  } else {
    showErrorElement(errorDiv);
  }
};

const handleAlternateLoginIdErrorsForPasswordReset = (error: any, submitButton: HTMLElement, precedingElement: Element, errorDiv: Element) => {
  if (error.getErrorCode() === 'gen.unexpected.multipleAccountMatch') {
    showErrorMessageElementToContactSupport(errorDiv);
  } else if (error.getErrorCode() === 'gen.institution-required') {
    hackyInstitutionPickerInserterForPasswordReset(submitButton, precedingElement, errorDiv);
    throw error;
  } else {
    submitButton.click();
  }
};

const showErrorMessageElementToContactSupport = (errorDiv: Element) => {
  const errorParagraph = errorDiv.children.item(1);
  if (errorParagraph !== null && errorParagraph?.tagName === 'P') {
    const helpLink = document.createElement('a');
    helpLink.href = 'https://support.cengage.com';
    helpLink.innerHTML = 'Cengage Support';
    helpLink.target = '_blank';
    helpLink.classList.add('cengage-support-link');
    errorParagraph.innerHTML = 'A sign in error occurred. Please contact ';
    errorParagraph.appendChild(helpLink);
    errorParagraph.append('.');
  }
  showErrorElement(errorDiv);
};

const addSubmitButtonListener = (submitButton: HTMLElement, wrapper: HTMLElement, errorDiv: Element) => {
  submitButton?.addEventListener('click', function (e) {
    const usernameInput = (document.getElementById('okta-signin-username') as HTMLInputElement);
    const passwordInput = (document.getElementById('okta-signin-password') as HTMLInputElement);
    if (institutionPickerError) {
      e.preventDefault();
      e.stopPropagation();
    }
    else if (!institution) {
      if (usernameInput?.value.length > 0 && passwordInput?.value.length > 0) {
        e.preventDefault();
        e.stopPropagation();
        addOktaError(errorDiv, 'We found some errors. Please review the form and make corrections.');
      }
      renderInstitutionPicker(wrapper, errorDiv, 'Please select an institution');
    }
  });
};

const hackyInstitutionPickerInserter = (submitButton: HTMLElement, precedingElement: Element, errorDiv: Element) => {
  const wrapper = document.getElementById('hacky-inserter-institution-picker') || createInstitutionPickerWrapperDiv(precedingElement);
  renderInstitutionPicker(wrapper, errorDiv, '');
  addSubmitButtonListener(submitButton, wrapper, errorDiv);
};

const hackyInstitutionPickerInserterForPasswordReset = (submitButton: HTMLElement, precedingElement: Element, errorDiv: Element) => {
  const wrapper = document.getElementById('hacky-inserter-institution-picker') || createInstitutionPickerWrapperDiv(precedingElement);
  renderInstitutionPicker(wrapper, errorDiv, '');
  if (errorDiv.children.length > 0) {
    errorDiv.removeChild(errorDiv.childNodes.item(0));
    errorDiv.classList.remove('o-form-has-errors');
  }
};

const renderInstitutionPicker = (parent: HTMLElement, errorDiv: Element, error: string) => {
  institutionPickerRendered = true;
  ReactDOM.render(
    <InstitutionPicker
      onChange={({ item }) => {
        institution = item;
      }}
      alternateLoginFlow={true}
      itemListMaxHeight="200px"
      helperMessage="Select your institution to sign in"
      errorMessage={error}
      onError={() => {
        addOktaError(errorDiv, 'An error occurred. Please try again later.');
        institutionPickerError = true;
      }}
    />,
    parent);
};

const addOktaError = (errorDiv: Element, errorMessage: string) => {
  const errorParagraph = errorDiv.children.item(1);
  if (errorParagraph !== null && errorParagraph?.tagName === 'P') {
    errorParagraph.textContent = errorMessage;
    showErrorElement(errorDiv);
  } else {
    if (!errorDiv.classList.contains('o-form-has-errors')) {
      errorDiv.classList.add('o-form-has-errors');
      errorDiv.appendChild(createOktaFormError(errorMessage));
    }
  }
};

const createInstitutionPickerWrapperDiv = (precedingElement: Element) => {
  const result = document.createElement('div');
  result.id = 'hacky-inserter-institution-picker';
  precedingElement.insertAdjacentElement('afterend', result);
  return result;
};
