export const getQueryString = () => {
  return '' || window.location.search;
};

export const getUserNameFromQuery = () => {
  const queryString = getQueryString();
  if (queryString.indexOf('LoginHint') > -1) {
    return queryString.split('LoginHint=')[1].split('&')[0];
  }
  return '';
};